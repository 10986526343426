<script>
import { Radar } from 'vue-chartjs'
export default {
  extends: Radar,
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },
  data() {
      return {
          options: {
            scale: {
                ticks: {
                    beginAtZero: true,
                    max: 10
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                }
                }
            }
          }
      }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>

<!--
//IN BLADE TEMPLATE
<line-chart :chartdata='{!! json_encode($chartdata) !!}'></line-chart>  
//IN PHP
        $dataset = [
            "label" => "Data one",
            "backgroundColor" => "#f87979",
            "data" => [40,20]
        ];
        $chartdata = [
            "labels" => ['A', 'B'],
            "datasets" => [
                (object)$dataset
            ]
        ];
        //die(json_encode((object)$chartdata, JSON_PRETTY_PRINT));
        return view('viewname',['chartdata' => (object)$chartdata]);
-->